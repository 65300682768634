export const skillData = [
    'JavaScript',
    'React',
    'C++',
    'Python',
    'Linux',
    'Tailwind',
    'Git',
    'MongoDB',
    'MySQL',
    'Java',
    'HTML',
    'NodeJS']
